import {InjectionToken} from '@angular/core';

export interface EnvironmentConfig {
  production: boolean;
  idsApiUrl: string;
  lsApiUrl: string;
  mpApiUrl: string;
  mpCoachUrlStudent: string;
  mpCoachUrlNonStudent: string;
  mpAccountUrl: string;
  mpManagerUrl: string;
  lsV10Url: string;
  cleverClientId: string;
  classLinkClientId: string;
  gg4lClientId: string;
  googleClientId: string;
  microsoftSSOClientId: string;
  version: string;
  applicationInsightsConnectionString: string;
  environment: ENV_CONFIG_ENV_TYPES;
}

export enum ENV_CONFIG_ENV_TYPES {
  DEV = 'dev',
  QA = 'qa',
  DEMO = 'demo',
  PROD = 'prod'
}

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>('EnvironmentConfig');

